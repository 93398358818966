import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { FC, useContext, useMemo } from "react";
import { NetworkContext } from "./NetworkContext";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-wallets";
import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";

type Props = {
  readonly children: React.ReactNode;
};

export const getCurrentNetwork = (
  networkType: string
): WalletAdapterNetwork => {
  switch (networkType) {
    case "mainnet":
      return WalletAdapterNetwork.Mainnet;
    case "testnet":
      return WalletAdapterNetwork.Testnet;
    case "devnet":
      return WalletAdapterNetwork.Devnet;
    case "localnet":
      // TODO - Cater for local connection
      return WalletAdapterNetwork.Devnet;
  }

  console.error(
    "Not a recognised solana network type, defaulting to devnet. NetworkType: ",
    networkType
  );
  return WalletAdapterNetwork.Devnet;
};

export const SolanaWalletProvider: FC<Props> = ({ children }) => {
  const { networkUrl, network } = useContext(NetworkContext);
  const walletAdapterNetwork = getCurrentNetwork(network);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({ network: walletAdapterNetwork }),
      new SolflareWalletAdapter({ network: walletAdapterNetwork }),
      // new LedgerWalletAdapter(), // - Commented as issue with imports
      new BackpackWalletAdapter({ network: walletAdapterNetwork }),
    ],
    [walletAdapterNetwork]
  );
  return (
    <ConnectionProvider endpoint={networkUrl}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};
