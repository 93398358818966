import React, { FC, useLayoutEffect, useMemo, useRef } from "react";
import gsap from "gsap";
import { formatCurrency } from "../../../utils/currency/formatting";

export interface GasLoadProps {
  startValue: number;
  endValue: number;
  total: number;
  solBalance: number;
  solBalanceUsd: number;
}

const GasLoad: FC<GasLoadProps> = ({
  startValue = 0,
  endValue: actualEndValue,
  total,
  solBalance,
  solBalanceUsd,
}) => {
  const arrowRef = useRef<HTMLInputElement | null>(null);
  const counterRef = useRef<HTMLInputElement | null>(null);
  const arcRef = useRef<HTMLInputElement | null>(null);
  const endValue = useMemo(
    () => (actualEndValue > total ? total + 1 : actualEndValue),
    [total, actualEndValue]
  );
  useLayoutEffect(() => {
    const angleRange = 270;
    const degInVal = angleRange / total;
    const startDeg = Math.floor((startValue + 1) * degInVal);
    const endDeg = Math.floor(
      endValue > total ? total * degInVal : endValue * degInVal
    );

    const strokeRange = 614;
    const strokeInVal = strokeRange / total;
    const startStroke = Math.floor(startValue * strokeInVal);
    const endStroke = Math.floor(
      endValue > total ? total * strokeInVal : endValue * strokeInVal
    );

    const relativeDuration =
      Math.floor(((endValue - startValue) / total) * 30) / 10;

    const duration = endValue === 0 ? 0 : relativeDuration;
    const ease =
      endValue + total / 10 > total ? "easy.out" : "elastic.out(0.2,0.1)";
    const delay = 0.5;
    const timeObj = {
      delay: delay,
      repeatDelay: 0.5,
      //   repeat: Infinity,
    };

    gsap.timeline(timeObj).fromTo(
      counterRef.current,
      { textContent: startValue },
      {
        textContent: endValue > total ? total : endValue,
        snap: {
          textContent: 1,
        },
        duration: duration,
        // ease: ease,
        onComplete: () => {
          const content = counterRef.current?.textContent;
          if (endValue > total && counterRef.current)
            counterRef.current.textContent = content + "+";
        },
      }
    );

    gsap.timeline(timeObj).fromTo(
      arrowRef.current,
      {
        rotation: startDeg,
        transformOrigin: "90% 5px",
      },
      {
        rotation: endDeg,
        transformOrigin: "90% 5px",
        ease: ease,
        duration: duration,
      }
    );

    gsap.timeline(timeObj).fromTo(
      arcRef.current,
      {
        "stroke-dasharray": `${startStroke} 835`,
      },
      {
        "stroke-dasharray": `${endStroke} 835`,
        ease: ease,
        duration: duration,
      }
    );
  }, [startValue, actualEndValue, total]);

  const label = {
    fill: "var(--gray-500)",
    fontSize: "16px",
    fontWeight: "700",
  };
  const mainText = {
    fill: "var(--gray-300)",
    fontSize: "36px",
    fontWeight: "700",
  };

  const gasAmount = {
    fill: "var(--gray-50)",
    fontWeight: "700",
    fontSize: "18px",
  };

  const circle = {
    strokeLinecap: "round",
    transformOrigin: "center",
    strokeWidth: "25px",
    strokeDasharray: "614, 835",
    transform: "rotate(138deg)",
  };
  // const arrow = {
  //   transformOrigin: 'center center',

  //   strokeLinecap: 'round',
  //   transform: 'rotate(-10deg)',
  // };
  const gas = useMemo(
    () => ({
      strokeWidth: endValue === 0 ? "0" : "25px",
      filter: "drop-shadow(0px 0px 12px #A34EFE80)",
      animation: "strock_aniamtion 1.5s ease-in-out infinite",
    }),
    [endValue]
  );

  return (
    <div className="flex justify-center w-full h-full">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 402 320"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          style={circle}
          cx="50%"
          cy="168"
          r="133"
          stroke="var(--gray-900)"
        ></circle>

        <circle
          ref={arcRef}
          style={{ ...circle, ...gas }}
          cx="50%"
          cy="168"
          r="133"
          stroke="url(#gradient)"
        ></circle>

        <text x="65" y="275" style={label}>
          0
        </text>
        <text x="0" y="175" style={label}>
          100
        </text>
        <text x="20" y="45" style={label}>
          200
        </text>
        <text x="340" y="45" style={label}>
          500
        </text>
        <text x="375" y="175" style={label}>
          600
        </text>
        <text x="310" y="275" style={label}>
          +700
        </text>

        <path
          ref={arrowRef}
          d="M130.496 224.111C128.18 226.289 124.546 226.21 122.328 223.932C120.11 221.654 120.127 218.019 122.366 215.762L191.171 146.421C194.127 143.442 198.951 143.465 201.879 146.472C204.806 149.478 204.701 154.301 201.644 157.176L130.496 224.111Z"
          fill="url(#paint0_linear_1084_37197)"
        />
        {/* <line
          ref={arrowRef}
          style={arrow}
          stroke="url(#paint0_linear_1084_37197)"
          strokeWidth="15"
          x1="50%"
          y1="50%"
          x2="90"
          y2="50%"
        /> */}
        <circle
          cx="145.5"
          cy="264.248"
          r="8"
          fill="url(#paint1_linear_1084_37197)"
        />

        <path
          d="M142.26 266.355C142.319 266.292 142.399 266.257 142.482 266.257H150.143C150.283 266.257 150.353 266.437 150.254 266.541L148.74 268.15C148.681 268.212 148.602 268.248 148.519 268.248H140.857C140.718 268.248 140.648 268.068 140.746 267.963L142.26 266.355Z"
          fill="#FBFFFD"
        />
        <path
          d="M142.26 260.345C142.319 260.283 142.399 260.248 142.482 260.248H150.143C150.283 260.248 150.353 260.427 150.254 260.532L148.74 262.141C148.681 262.203 148.602 262.238 148.519 262.238H140.857C140.718 262.238 140.648 262.059 140.746 261.954L142.26 260.345Z"
          fill="white"
        />
        <path
          d="M148.74 263.331C148.681 263.268 148.602 263.233 148.519 263.233H140.857C140.718 263.233 140.648 263.413 140.746 263.518L142.26 265.126C142.319 265.189 142.399 265.224 142.482 265.224H150.143C150.283 265.224 150.353 265.044 150.254 264.939L148.74 263.331Z"
          fill="white"
        />
        <text x="160" y="270" style={gasAmount} className="gas-amount">
          {formatCurrency(solBalance, 8, 8)}
        </text>
        <text
          x="50%"
          y="294"
          style={label}
          textAnchor="middle"
          className="label"
        >
          ~${formatCurrency(solBalanceUsd, 2, 4)}
        </text>

        <text
          x="48%"
          y="180"
          id="main-text"
          style={mainText}
          className="main-text"
          textAnchor="middle"
        >
          Actions
        </text>
        <text
          ref={counterRef}
          id="main-text"
          style={mainText}
          x="48%"
          y="140"
          className="main-text"
          textAnchor="middle"
        >
          10,000+
        </text>

        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="100%">
            <stop offset="0%" stopColor="var(--brand-pink)" />
            <stop offset="100%" stopColor="var(--brand-purple)" />
          </linearGradient>

          <linearGradient
            id="paint0_linear_1084_37197"
            x1="122.328"
            y1="223.932"
            x2="195.004"
            y2="153.166"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4E586D" />
            <stop offset="0.435762" stopColor="#394156" />
            <stop offset="1" stopColor="#21283B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1084_37197"
            x1="153.5"
            y1="258.248"
            x2="137.5"
            y2="272.248"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#12ECAB" />
            <stop offset="0.46355" stopColor="#6F8ED1" />
            <stop offset="1" stopColor="#CA31F7" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default GasLoad;
